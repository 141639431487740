import React from 'react';
import styled from 'styled-components';
import SEO from '../components/seo';

const PageWrapper = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }

  .page-header {
    margin-bottom: 2rem;
    flex: 1;
  }
  .main-content {
    flex: 4;
    margin-bottom: 4rem;
  }
`;

const Journal = () => (
  <PageWrapper>
    <SEO title="Journal" />
    <header className="page-header">
      <h1 className="page-title">Journal</h1>
    </header>
    <section className="main-content">
      <p>blog page - add journal loop here</p>
    </section>
  </PageWrapper>
);

export default Journal;
